import iconLogo from 'assets/images/logo.svg'
import { breakpointsMedias } from 'configs/breakpoints'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ILogo {
    type: 1 | 2 | 3
}


const Logo = ({ type }: ILogo) => {
    return (
        <Wrap>
            <Link to="" className="logo">
                <img className='' src={iconLogo} alt="" />
            </Link>
        </Wrap>
    )
}

export default Logo

const Wrap = styled.div`
    width: 100%;
    height: fit-content;
    cursor: pointer;
    .logo {
        width: 100%;
        height: fit-content;
        > img {
            width: 100%;
            height: auto;
        }
    }
    ${breakpointsMedias.max1440} {
        
    }
    ${breakpointsMedias.max1199} {
    }
`
import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import logo2 from 'assets/images/logo-2.png'
import iconDiscord from 'assets/images/icon-discord.png'
import iconTele2 from 'assets/images/icon-telegram-2.png'
import iconTwitter2 from 'assets/images/icon-twitter-2.png'
import iconTooltip from 'assets/images/icon-tooltip.png'
import Button from 'components/core/Button'

interface IInfor {
    onNext?: () => void
}

const Infor = ({ onNext }: IInfor) => {
    const links = [
        {
            link: "https://twitter.com/BasebotTeam",
            icon: iconTwitter2,
        },
        {
            link: "https://t.me/BasebotTeam",
            icon: iconTele2,
        },
        // {
        //     link: "https://discord.com/invite/Z2nuHDgz5R",
        //     icon: iconDiscord,
        // },
    ]

    return (<Wrap>
        <div className="infor-top">
            <div className="it-img">
                <img src={logo2} alt="" />
            </div>
            <div className="it-name">
                <span className="size-3-3 color-white">Basebot</span>
                <div className="it-name-list">
                    {links.map((item, index) => <a href={item.link} target='_blank' rel='noreferrer' key={index} className='jl-item'>
                        <img src={item.icon} alt="" />
                    </a>)}
                </div>
            </div>
            <div className="it-price">
                <span className="size-1-2">PRICE: 0.004 USD</span>
                <div className="">
                    <img src={iconTooltip} alt="" />
                </div>
            </div>
        </div>
        <span className="size-2 color-white">Basebot, the Ultimate Telegram Trading Bot, one-of-a-kind features. It operates on Base and routes trades to Uniswap V3. Enjoy seamless trading with advanced tools to spot profitable trades, earn rewards through referrals, and join a community of like-minded traders.</span>
        <Button text='More Details' arr typeBt={2} onClick={onNext} />
    </Wrap>)
}

export default Infor

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 32px;
    .infor-top {
        display: flex;
        width: 100%;
        padding-bottom: 25px;
        border-bottom: 1px solid #393939;
        margin-bottom: 24px;
        .it-img {
            width: 80px;
            height: fit-content;
            display: flex;
            margin-right: 12px;
            > img {
                width: 100%;
                height: auto;
            }
        }
        .it-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            > span {
                margin-bottom: 6px;
            }
            .it-name-list {
                display: flex;
                align-items: center;
                > a {
                    width: 24px;
                    display: flex;
                    height: fit-content;
                    margin-right: 10px;
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            
        }
        .it-price {
                display: flex;
                margin-left: auto;
                border-radius: 44px;
                background: #424045;
                padding: 10px 20px;
                align-items: center;
                height: fit-content;
                > span {
                    margin-right: 6px;
                }
                > div {
                    width: 24px;
                    height: fit-content;
                    display: flex;
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
        }
    }
    > button {
        display: none;
    }
    ${breakpointsMedias.max1199} {
        padding: 21px;
        .infor-top {
            padding-bottom: 12px;
            margin-bottom: 12px;
            .it-img {
                width: 53px;
                margin-right: 6px;
            }
            .it-name {
                .it-name-list {
                    > a {
                        width: 16px;
                        margin-right: 6px;
                    }
                }
                
            }
            .it-price {
                padding: 10px;
                > div {
                    width: 16px;
                }
            }
        }
    }
    ${breakpointsMedias.max767} {
        .infor-top {
            flex-wrap: wrap;
            .it-name {
               min-width: calc(100% - 70px);
            }
            .it-price {
                margin-left: unset;
                margin-right: auto;
                padding: 0 10px;
                margin-top: 12px;
            }
        }
        > button {
            display: flex;
            margin-top: 10px;
        }
    }
`


import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import configColor from 'configs/configColor'
import { breakpointsMedias } from 'configs/breakpoints'

interface IMenu {
    onClose: () => void
    onShowComming: () => void
}

export const MENU_LIST = [
    {
        text: "docs",
        link: "https://basebot.gitbook.io/docs/",
        exlink: true
    },
    {
        text: "community",
        link: "https://link3.to/basebot",
        exlink: true
    },
    {
        text: "launchpad",
        link: null,
    },
    {
        text: "claimRewards",
        link: null,
        exlink: true
    },
];

const Menu = ({ onClose, onShowComming }: IMenu) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <Wrap >
            <div className="menu-img"></div>
            <div className={`menu-list`}>
                {
                    MENU_LIST.map((item, index) => {
                        return item.link ? <a href={item.link}
                            key={index}
                            className={`menu-item ${pathname.split("/")[1] === item.text ? "menu-item-active" : ""}`}
                            onClick={() => {
                                onClose()
                            }}
                            target={item.exlink ? "_blank" : ""}
                            rel="noreferrer"
                        >
                            <span className={`size-2 color-white`}>{t(item.text)}</span>
                        </a>
                            : <div
                                key={index}
                                className={`menu-item ${pathname.split("/")[1] === item.text ? "menu-item-active" : ""}`}
                                onClick={() => {
                                    onShowComming();
                                    onClose()
                                }}
                            >
                                <span className={`size-2 color-white`}>{t(item.text)}</span>
                            </div>
                    }
                    )
                }
                {/* <a href="" target='_blank' className="bt-trade" rel="noreferrer">
                    <span className='size-2 color-white'>{t("trade")}</span>
                </a> */}
                <a href='https://basebot.dev/' target='_blank' rel="noreferrer" className="bt-trade" >
                    <span className='size-2 color-white'>{t("trade")}</span>
                </a>
            </div>

        </Wrap>
    )
}

export default Menu

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #1A1A1A;
    position: relative;
    
    ${breakpointsMedias.min481} {
        max-width: 303px;
    }
    padding: 57px 20px;
    .menu-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
   
    .menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        z-index: 0;
        height: fit-content;
        .menu-item {
            /* margin-bottom: 16px; */
            cursor: pointer;
            color: ${configColor.lightWhite};
            display: flex;
            align-items: center;
            width: fit-content;
            margin-bottom: 0px;
            margin-top: 16px;
            > span {
                display: flex;
                align-items: center;
            }
            &:hover{
                > span {
                    color: ${configColor.colorPrimary};
                }
            }
        }
        .bt-trade {
            border-radius: 30px;
            border: 1px solid var(--white, #FFF);
            height: 32px;
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                background: #FFFF;
                > span {
                    color: black;
                }
            }
        }
        .menu-item-active {
            > span {
               color: ${configColor.colorPrimary};
            }
        }
    }
    ${breakpointsMedias.min1200} {
        flex-direction: row;
        background-color: transparent;
        max-width: unset;
        background: none;
        padding: 0;
        .menu-img {
            display: none;
        }
        .menu-list {
            border: none;
            flex-direction: row;
            align-items: flex-end;
            padding: 0;
            height: 55px;
            .menu-item {
                margin: 0 17px;
                padding: 0;
                &:first-child {
                    margin-left: auto;
                }
            }
            .bt-trade {
                margin-left: 33px;
                height: 55px;
                width: 200px;
                margin-top: 0;
            }
        }
    }
    ${breakpointsMedias.min1400} {
        .menu-list {
            .menu-item {
                margin: 0 20px;
            }
        }
    }
`

interface ISocicalItem {
    active: string
}

const SocicalItem = styled.div`
    background-color: #FFFFFF;
    -webkit-mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask-size: contain;
    /* transition: 0.6s ease-in-out; */
    &:hover {
        background: ${configColor.colorPrimary};
    }
`
import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import Infor from './Infor'
import Pool from './Pool'
import Token from './Token'
import TokenDistribution from './TokenDistribution'
import Presale from './Presale'
import Main from './Main'
import Referral from './Referral'
import { useWidthScreen } from 'helpers/useScreen'
import { useState } from 'react'
import iconArr from 'assets/images/icon-arrow-back.png'


const Launchpad = () => {
    const { width } = useWidthScreen();
    const [page, setPage] = useState<number>(1)

    return (<Wrap>
        <div className="container">
            {width >= 768 ? <>
                <div className="lp-left">
                    <div className="ll-infor">
                        <Infor />
                    </div>
                    <div className="ll-pool-token">
                        <div className="ll-pool">
                            <Pool />
                        </div>
                        <div className="ll-pool">
                            <Token />
                        </div>
                    </div>
                    <div className="ll-tokenomic">
                        <TokenDistribution />
                    </div>
                </div>
                <div className="lp-right">
                    <Presale />
                    <Main />
                    <Referral />
                </div>
            </> : <>
                {page === 1 ? <>
                    <Infor onNext={() => { setPage(2) }} />
                    <Presale />
                    <Referral />
                    <Main />
                </> : <>
                    <div className="bt-back" onClick={() => { setPage(1) }}>
                        <img src={iconArr} alt="" />
                    </div>
                    <Pool />
                    <Token />
                    <TokenDistribution />
                </>}
            </>}

        </div>
    </Wrap>)
}

export default Launchpad

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -176px;
    padding-top: 220px;
    ${breakpointsMedias.max1399} {
        margin-top: -126px;
        padding-top: 170px;
    }
    min-height: 100vh;
    background: #1E1E1E;
   
    padding-bottom: 237px;
    text-transform: capitalize;
    .container {
        display: flex;
        gap: 32px;
        .lp-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 32px;
            align-items: stretch;
            /* min-height: 100%; */
            > div {
                display: flex;
                gap: 32px;
                .ll-pool {
                    flex: 1;
                    align-self: stretch;
                }
            }
            .ll-tokenomic {
                flex: 1;
                justify-self: stretch;
            }
        }
        .lp-right {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 35%;
        }
    }
    ${breakpointsMedias.max1540} {
        .container {
            flex-direction: column;
            gap: 32px;
            .lp-left {
                flex: 1;
                gap: 32px;
                > div {
                    gap: 32px;
                }
            }
            .lp-right {
                gap: 32px;
                width: 100%;
            }
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
        padding-top: 97px;
        padding-bottom: 170px;
        .container {
            gap: 24px;
            .lp-left {
                flex: 1;
                gap: 24px;
                > div {
                    gap: 24px;
                }
            }
            .lp-right {
                gap: 24px;
                width: 100%;
            }
        }
    }
    ${breakpointsMedias.max767} {
        .container {
            .bt-back {
                width: 24px;
                height: 24px;
                margin-right: auto;
                cursor: pointer;
                > img {
                    width: 24px;
                    height: auto;
                }
            }
        }
    }
`


import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import logo2 from 'assets/images/logo-2.png'
import Button from 'components/core/Button'
import numeral from 'numeral'
import configColor from 'configs/configColor'
import { useEffect, useState } from 'react'


const MainRange = () => {
    const [data, setData] = useState({
        busd: 100001.47,
        value: 0,
        max: 2857142
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setTimeout(() => {
            setData({
                ...data,
                value: data.max
            })
        }, 500)
    }

    return (<Wrap>
        <div className="mr-text">
            <span className="size-1 color-white text-capital">finished 3 months, 3 days ago</span>
            <span className="size-1 color-gray">100%</span>
        </div>
        <div className="mr-range">
            <div className="mrr-value" style={{ width: `${data.value / data.max * 100}%` }}></div>
        </div>
        <div className="mr-value">
            <span className="size-1 color-gray">{numeral(data.busd).format("0,0.[00]")} BUSD</span>
            <span className="size-1 color-gray">{numeral(data.value).format("0,0.[00]")} / {numeral(data.max).format("0,0.[00]")} BBT</span>
        </div>
    </Wrap>)
}

export default MainRange

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .mr-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .mr-range {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 35px;
        background: #101010;
        height: 25px;
        padding: 3px;
        .mrr-value {
            border-radius: 35px;
            transition: 0.5s;
            height: 100%;
            background: var(--gradient, linear-gradient(86deg, #0052FF 0%, #7000FF 100%));
        }
    }
    .mr-value {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    ${breakpointsMedias.max1199} {

    }
`


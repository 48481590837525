import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import imgTokenDis from 'assets/images/token-dis.png'
import imgToken2 from 'assets/images/home-token-2.png'

const TokenDistribution = () => {

    return (<Wrap>
        <span className="size-2 color-white pool-title">Tokenomics Distribution</span>
        <div className="pool-main">
            <img src={imgTokenDis} alt="" />
        </div>
    </Wrap>)
}

export default TokenDistribution

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 24px 32px;
    min-height: 100%;
    .pool-title {
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid #393939;
    }
    .pool-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        flex: 1;
        > img {
            width: 100%;
            height: auto;
            /* max-width: 735px; */
            max-width: 680px;
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 28px 18px 80px 18px;
        min-height: unset;
        .pool-main {
            margin-top: 67px;
            flex: 1;
            > img {
                ${breakpointsMedias.max376} {
                    content: url(${imgToken2});
                }
            }
        }
    }
`


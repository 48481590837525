import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'

const Pool = () => {

    return (<Wrap>
        <span className="size-2 color-white pool-title">Pool Details</span>
        <div className="pool-main">
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Hard Cap</span>
                <span className="size-1 color-white pmi-value">100 ETH</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Soft Cap</span>
                <span className="size-1 color-white pmi-value">20 ETH</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Rate</span>
                <span className="size-1 color-white pmi-value">1 BBT = $0.002</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Listing Rate</span>
                <span className="size-1 color-white pmi-value">1 BBT = $0.003</span>
            </div>
            {/* <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Swap Rate</span>
                <span className="size-1 color-white pmi-value pmi-value-2">1 BBT = $0.035
                    <span className="size-0 color-white">28.571 BBT per 1 BUSD</span>
                </span>
            </div> */}
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Start</span>
                <span className="size-1 color-white pmi-value">13:00 UTC, Sep 7th</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">End</span>
                <span className="size-1 color-white pmi-value">13:00 UTC, Sep 9th</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Unlock at TGE</span>
                <span className="size-1 color-white pmi-value">100%</span>
            </div>
            {/* <div className="pm-item">
                <span className="size-1 color-gray pmi-title">FCFS Opens</span>
                <span className="size-1 color-white pmi-value">May 13, 12:20 UTC</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Base Allocation</span>
                <span className="size-1 color-white pmi-value">1x = $5.5</span>
            </div> */}
        </div>
    </Wrap>)
}

export default Pool

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 32px;
    min-height: 100%;
    .pool-title {
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid #393939;
    }
    .pool-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        .pm-item {
            width: 100%;
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .pmi-value-2 {
                display: flex;
                flex-direction: column;
                text-align: right;
                margin-top: -11px;
                margin-bottom: -11px;
                > span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px; 
                    color: #729FFF;
                }
            }
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 22px 22px 28px 22px;
        min-height: unset;
        .pool-title {
            padding-bottom: 12px;
        }
        .pool-main {
            .pm-item {
                width: 100%;
                margin-top: 24px;
                &:first-child {
                    margin-top: 12px;
                }
                .pmi-value-2 {
                    display: flex;
                    flex-direction: column;
                    text-align: right;
                    margin-top: -11px;
                    margin-bottom: -11px;
                }
            }
        }
    }
`


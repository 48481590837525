import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'

const Token = () => {

    return (<Wrap>
        <span className="size-2 color-white pool-title">Token</span>
        <div className="pool-main">
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Token</span>
                <span className="size-1 color-white pmi-value">Basebot Token</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Ticker</span>
                <span className="size-1 color-white pmi-value">$BBT</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Total Supply</span>
                <span className="size-1 color-white pmi-value">1,000,000,000 BBT</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Initial Supply</span>
                <span className="size-1 color-white pmi-value">358,000,000 (35.8%)</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Token For Sale</span>
                <span className="size-1 color-white pmi-value">250,000,000 (25%)</span>
            </div>

            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Listing</span>
                <span className="size-1 color-white pmi-value">08:00 UTC, Sep 10th</span>
            </div>
            <div className="pm-item">
                <span className="size-1 color-gray pmi-title">Address</span>
                <a href='' target='_blank' className="size-1 color-white pmi-value">0x8...0a1</a>
            </div>
        </div>
    </Wrap>)
}

export default Token

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 32px;
    min-height: 100%;
    .pool-title {
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid #393939;
    }
    .pool-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        .pm-item {
            width: 100%;
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 22px 22px 28px 22px;
        min-height: unset;
        .pool-title {
            padding-bottom: 12px;
        }
        .pool-main {
            .pm-item {
                width: 100%;
                margin-top: 24px;
                &:first-child {
                    margin-top: 12px;
                }
            }
        }
    }
`


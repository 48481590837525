import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import iconCopy from 'assets/images/icon-copy.png'
import Button from 'components/core/Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useRef } from 'react'

const Referral = () => {
    const copyRef = useRef<HTMLSpanElement>(null)


    return (<Wrap className='ll-ref'>
        <span className="size-2 color-white ref-title">Launchpad Referral</span>
        <div className="ref-main">
            <span className="size-1 color-white-2">You will earn 5% of ETH from participants who purchased with your referral link.</span>
            <div className="rm-input">
                <span className="size-1 color-gray-2">Basebot.net/buyplots/ref1238763248</span>
                <CopyToClipboard text={"Basebot.net/buyplots/ref1238763248"} onCopy={() => {
                    if (copyRef.current) {
                        copyRef.current.style.display = "flex";
                        setTimeout(() => {
                            if (copyRef.current) { copyRef.current.style.display = "none"; }
                        }, 2000)
                    }
                }}>
                    <div className="rm-copy">
                        <img src={iconCopy} alt="" />
                        <span ref={copyRef} className="size-0 color-blue copied" > Copied!</span >
                    </div>
                </CopyToClipboard>
            </div>
            <span className="size-2 color-white">Your Rewards: 0.34 ETH</span>
            <Button typeBt={2} text='Claim' onClick={() => { }} />
        </div>
    </Wrap>)
}

export default Referral

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 32px;
    .ref-title {
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid #393939;
        margin-bottom: 24px;
    }
    .ref-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        .rm-input {
            border-radius: 16px;
            background: #1D1D1D;
            display: flex;
            align-items: center;
            height: 76px;
            padding: 0 24px;
            > span {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .rm-copy {
                width: 32px;
                height: 32px;
                margin-left: 24px;
                cursor: pointer;
                position: relative;
                > img {
                    width: 32px;
                    height: auto;
                }
                > .copied {
                    position: absolute;
                    width: fit-content;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: #f4f0f0;
                    left: 0;
                    top: 100%;
                    display: none;
                    z-index: 1;
                }
            }
        }
        > button {
            max-width: unset;
            ${breakpointsMedias.max1540} {
                max-width: 272px;
            }
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 32px 26px;
        .ref-title {
            padding-bottom: 12px;
            margin-bottom: 12px;
        }
        .ref-main {
            gap: 12px;
            .rm-input {
                height: 51px;
                padding: 0 10px;
                .rm-copy {
                    margin-left: 16px;
                }
            }
        }
    }
`


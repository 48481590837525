import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from './LoadingSpinner';
import configColor from 'configs/configColor';
import { breakpointsMedias } from 'configs/breakpoints';
import iconArr from 'assets/images/icon-arrow-left.png'

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    isLoading?: boolean,
    className?: string,
    disabled?: boolean,
    typeBt?: 1 | 2,
    arr?: boolean
}

const Button = ({ text, isLoading = false, typeBt = 1, className, disabled = false, arr = false, ...props }: IB) => {
    const { t } = useTranslation();
    return (
        <Wrap className={`${isLoading ? "bt-loading" : ""} bt-${typeBt} ${className}`} disabled={disabled || isLoading} {...props}>
            <span className={`size-2`}>{!!isLoading ? <LoadingSpinner />
                : <>
                    {t(text)}
                    {arr && <img src={iconArr} alt="" />}
                </>}</span>
        </Wrap>
    )
}
export default Button

const Wrap = styled.button`
    max-width: 247px;
    width: 100%;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
    transition: 0.3s;
    > span {
        display: flex;
        align-items: center;
        > img {
            width: 24px;
            height: auto;
            margin-left: 10px;
        }
    }
    &.bt-1 {
        background-color: ${configColor.colorWhite};
        > span {
            color: black;
        }
        &:hover {
            border: 1px solid  ${configColor.colorWhite};
            background-color: #1E1E1E;
            > span {
                color: white;
            }
        }
    }
    &.bt-2 {
        background-color: #0052FF;
        box-shadow: 0px 3px 28px 8px rgba(79, 139, 228, 0.40);
        > span {
            color: white;
        }
        &:hover {
            border: 1px solid #0052FF;;
            background-color: #1E1E1E;
        }
    }
    
    

    &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.bt-loading {
        opacity: 0.4;
        cursor: not-allowed;
    }
    ${breakpointsMedias.max1199} {
        max-width: 208px;
        height: 50px;
        cursor: pointer;
        border-radius: 8px;
    }
`
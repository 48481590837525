import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import Countdown from 'react-countdown'
import { useState } from 'react'

const Presale = () => {
    const now = Date.now()
    const start = Date.now() + 10000
    const end = Date.now() + 20000;

    const [stage, setStage] = useState<number>(start > now ? start : end)

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        // console.log({ hours, minutes, seconds, completed })
        return <div className={`pre-wrap ${completed && "pre-wrap-end"}`}>
            <span className="size-2 color-white pool-title">Presale {completed ? "ended" : stage === start ? "starts in" : "ends in"}</span>
            <div className='mcd'>
                {/* <span className="size-2 color-white mcd-val">{days > 10 ? Math.floor(days / 10) : 0}</span>
            <span className="size-2 color-white mcd-val">{days > 10 ? days % 10 : days}</span>
            <span className="color-gray size-4">:</span> */}
                <span className="size-2 color-white mcd-val">{hours > 10 ? hours.floor(hours / 10) : 0}</span>
                <span className="size-2 color-white mcd-val">{hours > 10 ? hours % 10 : hours}</span>
                <span className="color-gray size-4">:</span>
                <span className="size-2 color-white mcd-val">{minutes > 10 ? Math.floor(minutes / 10) : 0}</span>
                <span className="size-2 color-white mcd-val">{minutes > 10 ? minutes % 10 : minutes}</span>
                <span className="color-gray size-4">:</span>
                <span className="size-2 color-white mcd-val">{seconds > 10 ? Math.floor(seconds / 10) : 0}</span>
                <span className="size-2 color-white mcd-val">{seconds > 10 ? seconds % 10 : seconds}</span>
            </div>
        </div>
    };


    return (<Wrap className='ll-presale'>
        <Countdown
            date={stage}
            renderer={renderer}
            onComplete={(e) => {
                console.log(e)
                if (stage === start) {
                    setStage(end);
                }
            }}
            autoStart
            key={stage}
        />
    </Wrap>)
}

export default Presale

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 32px;
    .pool-title {
        margin-bottom: 24px;
        width: 100%;
    }
    .pre-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        .mcd {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
            max-width: 600px;
            .mcd-val {
                display: flex;
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                background: #1E1E1E;
            }
        }
    }
    .pre-wrap-end {
        span {
            color: #898989 !important;
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 16px 12px 28px 12px;
        .pool-title {
            margin-bottom: 14px;
            padding-left: 10px;
        }
        .pre-wrap {
            .mcd {
                max-width: 300px;
                .mcd-val {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
`


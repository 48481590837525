import { createGlobalStyle } from "styled-components";
import { breakpointsMedia, breakpointsMedias } from "./breakpoints";
import configColor from "./configColor";
export const GlobalStyle = createGlobalStyle`
  :root{
    font-size:14px;
    line-height:1.254;
    letter-spacing: 0.15px;
  }
  body {
    background-color: #1E1E1E;
  }
  .container {
    width: 100%;
    padding: 0 120px;
    max-width: 1920px;
  }
  .ball {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    height: fit-content;
    z-index: 0;
  }

  ${breakpointsMedias.max1199}{
      .container {
        padding: 0 40px;
      }
  }
  ${breakpointsMedias.max767}{
      .container {
        padding: 0 16px;
      }
  }
`;

import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import logo2 from 'assets/images/logo-2.png'
import icon1 from 'assets/images/icon-order.png'
import iconBnb from 'assets/images/icon-bnb.png'
import Button from 'components/core/Button'
import MainRange from './MainRange'


const Main = () => {


    return (<Wrap className='ll-main'>
        <div className="infor-top">
            <div className="it-img">
                <img src={logo2} alt="" />
            </div>
            <div className="it-name">
                <span className="size-3-3 color-white">Basebot</span>
                <span className="size-1 color-white">BBT / BUSD</span>
                <div className="it-name-status">
                    <span className="size-1 color-white">Closed</span>
                </div>
            </div>
        </div>
        <div className="infor-bt">
            <Button typeBt={2} text='BUY BBT' onClick={() => { }} />
            <Button typeBt={1} text='View chart' onClick={() => { }} />
        </div>
        <span className="size-3-2 ib-price-1">1 BUSD = 28.571 BBT</span>
        <span className="size-2 ib-price-2">1 BBT = 0.035 BUSD</span>
        <MainRange />
        <div className="infor-time">
            <div className="ift-item">
                <div className="ifti-img">
                    <img src={iconBnb} alt="" />
                </div>
                <span className="size-1 color-white text-capital">IDO and distribution on Base Chain</span>
            </div>
            <div className="ift-item">
                <div className="ifti-img">
                    <img src={icon1} alt="" />
                </div>
                <span className="size-1 color-white text-capital"><span>Sale</span>May 12, 14:00 – May 13, 13:00 UTC</span>
            </div>
            <div className="ift-item">
                <div className="ifti-img">
                    <img src={icon1} alt="" />
                </div>
                <span className="size-1 color-white text-capital"><span>FCFS</span>May 13, 12:20 UTC</span>
            </div>
        </div>
    </Wrap>)
}

export default Main

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #28272B;
    padding: 32px;
    .infor-top {
        display: flex;
        width: 100%;
        margin-bottom: 32px;
        .it-img {
            width: 127px;
            height: fit-content;
            display: flex;
            margin-right: 32px;
            > img {
                width: 100%;
                height: auto;
            }
        }
        .it-name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .it-name-status {
                border-radius: 12px;
                background: #424045;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                height: 37px;
                min-width: 94px;
            }
        }
    }
    .infor-bt {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 33px;
    }
    .ib-price-1 {
        margin-bottom: 6px;
    }
    .ib-price-2 {
        margin-bottom: 24px;
    }
    .infor-time {
        display: flex;
        flex-direction: column;
        width: 100%;
        .ift-item {
            display: flex;
            /* align-items: center; */
            margin-top: 24px;
            .ifti-img {
                background-color: #0052FF;
                border-radius: 50%;
                padding: 7px;
                display: flex;
                height: fit-content;
                margin-right: 12px;
                > img {
                    width: 14px;
                    height: auto;
                }
            }
            > span {
                > span {
                    display: inline-block;
                    min-width: 80px;
                    margin-right: 12px;
                }
            }
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 21px;
        .infor-top {
            .it-img {
                width: 84px;
                margin-right: 6px;
            }
            .it-name {
                .it-name-status {
                    border-radius: 50px;
                    height: 31px;
                    min-width: 88px;
                    padding-bottom: 3px;
                    margin-top: 2px;
                }
            }
        }
        .infor-bt {
            ${breakpointsMedias.max376} {
                flex-direction: column;
                > button {
                    max-width: unset;
                }
            }
        }
        .infor-time {
            .ift-item {
                .ifti-img {
                    padding: 4px;
                    > img {
                        width: 9px;
                    }
                }
                > span {
                    > span {
                        min-width: 40px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
`

